import React, { useEffect, useRef } from "react";
import styles from "./Description.module.css";
import { AppsDescription } from "../shared/apps";

const defaultDescription = `At <span>Hydrosoft</span>, we deliver high-quality software
  development solutions. Our team specializes in technologies like C++,
  JavaScript, React, PHP, CSS, and Angular 2, and we embrace the agile
  Scrum methodology to ensure adaptability and efficiency in our
  processes. We pride ourselves on our ability to tackle complex
  architectural and infrastructural challenges, particularly in cloud
  services. Our commitment to <span>code quality</span> and support
  ensures that you receive reliable and <span>cost-effective</span>
  technological solutions tailored to your business needs.`;

export function Description({ selectedApp }: { selectedApp?: string }) {
  const [description, setDescription] =
    React.useState<string>(defaultDescription);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.animate(
      { opacity: [1, 0] },
      { duration: 400, iterations: 1, easing: "ease-in-out" }
    );

    if (selectedApp) {
      setDescription(
        AppsDescription[selectedApp as keyof typeof AppsDescription]
      );
    } else {
      setDescription(defaultDescription);
    }

    ref.current?.animate(
      { opacity: [0, 1] },
      { duration: 400, iterations: 1, easing: "ease-in-out" }
    );
  }, [selectedApp]);

  return (
    <section className={`${styles.content} ${styles.fadeIn}`} ref={ref}>
      {selectedApp && <p className={styles.title}>{selectedApp}</p>}
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
    </section>
  );
}
