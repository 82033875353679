import React, { useEffect } from "react";

export function useTheme() {
  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  const [theme, setTheme] = React.useState(
    mediaQuery.matches ? "dark" : "light"
  );

  useEffect(() => {
    const handler = (event: MediaQueryListEvent) => {
      setTheme(event.matches ? "dark" : "light");
    };
    mediaQuery.addEventListener("change", handler);
    return () => mediaQuery.removeEventListener("change", handler);
  }, [mediaQuery]);

  //   #61A3BA
  // #FFFFDD
  // #D2DE32
  // #A2C579
  useEffect(() => {
    if (theme === "light") {
      document.documentElement.style.setProperty("--background", "#ffffdd");
      document.documentElement.style.setProperty("--primary", "#d2de32");
      document.documentElement.style.setProperty("--secondary", "#a2c579");
      document.documentElement.style.setProperty("--text", "#282c34");
      document.documentElement.style.setProperty("--text-light", "#ffffff");
      document.documentElement.style.setProperty("--accent", "#61a3ba");
    } else {
      document.documentElement.style.setProperty("--background", "#176B87");
      document.documentElement.style.setProperty("--primary", "#64CCC5");
      document.documentElement.style.setProperty("--secondary", "#001C30");
      document.documentElement.style.setProperty("--text", "#fff");
      document.documentElement.style.setProperty("--text-light", "#197e8e");
      document.documentElement.style.setProperty("--accent", "#001C30");
    }
  }, [theme]);
}
