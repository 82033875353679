import React from "react";
import styles from "./AppsCircles.module.css";

export function AppsCircles({
  name,
  link,
  diametr = 100,
}: {
  name: string;
  link: string;
  diametr?: number;
}) {
  const handleClick = () => {
    window.open(link, "_blank");
  };
  return (
    <div
      className={styles.appCircle}
      style={{ width: diametr, height: diametr }}
    >
      <p className={styles.appText} onClick={handleClick}>
        {name}
      </p>
    </div>
  );
}
