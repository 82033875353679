const calculateAngle = (index: number, length: number) => {
  if (length === 0) {
    return 0;
  }

  return ((2 * Math.PI) / length) * index;
};

const calculatePlacementRadius = (
  mainDiameter: number,
  appDiameter: number,
  margin: number
) => {
  const mainRadius = mainDiameter / 2;
  const appRadius = appDiameter / 2;
  return mainRadius + appRadius + margin;
};

const calculateRadians = (angle: number) => (angle * Math.PI) / 180;

const calculateCoordinates = (
  angle: number,
  placementRadius: number,
  mainCenterX: number,
  mainCenterY: number,
  appDiameter: number,
  offsetAngleDegrees = calculateRadians(0)
) => {
  const appRadius = appDiameter / 2;
  const offsetAngleRadians = calculateRadians(offsetAngleDegrees);
  const adjastedAngle = angle + offsetAngleRadians;

  const x = mainCenterX + placementRadius * Math.cos(adjastedAngle) - appRadius;
  const y = mainCenterY + placementRadius * Math.sin(adjastedAngle) - appRadius;

  return { x, y };
};

/**
 * Calculate position of the app circule
 * @param index - index of the app circle
 * @param length - length of the app circles list
 * @param mainDiametr - diametr of the main circle
 * @param appDiametr - diametr of the app circle
 * @param mainCenterX - X coordinate of the main circle center
 * @param mainCenterY - Y coordinate of the main circle center
 * @param margin - margin between main and app circles
 * @returns {x, y} - coordinates of the app circle
 * @example
 * calcPosition(0, 4, 100, 50, 100, 100, 10) // {x: 140, y: 100}
 */
export const calcPosition = (
  index: number,
  length: number,
  mainDiametr: number,
  appDiametr: number,
  mainCenterX: number,
  mainCenterY: number,
  margin: number,
  offsetAngleDegrees?: number
) => {
  const normalizedAngle = offsetAngleDegrees ? offsetAngleDegrees % 360 : 0;
  if (length === 0) {
    const radius = mainDiametr / 2;
    return { x: mainCenterX + radius, y: mainCenterY + radius };
  }

  const placementRadius = calculatePlacementRadius(
    mainDiametr,
    appDiametr,
    margin
  );

  const angle = calculateAngle(index, length);

  const { x, y } = calculateCoordinates(
    angle,
    placementRadius,
    mainCenterX,
    mainCenterY,
    appDiametr,
    normalizedAngle
  );

  return { x, y };
};
