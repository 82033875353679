import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { AppsCircles } from "../AppsCircles/AppsCircles";
import { MainCircle } from "../MainCircle/MainCircle";
import styles from "./CirclesList.module.css";
import { calcPosition } from "./helper/calcPosition";
import { Apps } from "../../shared/apps";
import { useSize } from "../../../hooks/useSize/useSize";

export function CircleList({
  setSelectedApp,
}: {
  setSelectedApp: Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const mainDiametr = 250;
  const appDiametr = 100;
  const margin = 40;

  const ref = useRef<HTMLDivElement>(null);
  const [coords, setCoords] = React.useState({ x: 0, y: 0 });
  const [offsetAngle, setOffsetAngle] = React.useState(0);
  const windowSize = useSize();

  let interval = useRef<NodeJS.Timeout>();

  const startRotation = useCallback(() => {
    interval.current = setInterval(() => {
      setOffsetAngle((prev) => (prev < 360 ? prev + 1 : 0));
    }, 200);
  }, []);

  const stopRotation = useCallback(() => {
    clearInterval(interval.current);
  }, []);

  const handleMouseLeave = useCallback(() => {
    startRotation();
    setSelectedApp(undefined);
  }, [setSelectedApp, startRotation]);

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const key = e.currentTarget.dataset.key;

      setSelectedApp(key);
      stopRotation();
    },
    [setSelectedApp, stopRotation]
  );

  useEffect(() => {
    startRotation();

    return () => {
      clearInterval(interval.current);
    };
  }, [startRotation]);

  useEffect(() => {
    if (ref.current) {
      const { x, y } = ref.current.getBoundingClientRect();

      const result = {
        x: x + mainDiametr / 2,
        y: y + mainDiametr / 2,
      };

      setCoords(result);
    }
  }, [mainDiametr, windowSize]);

  const appsLength = useMemo(() => Object.entries(Apps).length, []);

  return (
    <section className={styles.circleList}>
      <MainCircle diametr={mainDiametr} ref={ref} />
      {Object.entries(Apps).map(([key, value], index) => {
        const position = calcPosition(
          index,
          appsLength,
          mainDiametr,
          appDiametr,
          coords.x,
          coords.y,
          margin,
          offsetAngle
        );

        return (
          <div
            key={index}
            data-key={key}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              position: "absolute",
              left: `${position.x}px`,
              top: `${position.y}px`,
              transition: "left 200ms linear, top 200ms linear",
            }}
          >
            <AppsCircles key={index} name={key} link={value} />
          </div>
        );
      })}
    </section>
  );
}
