import React, { ReactNode, forwardRef } from "react";
import styles from "./MainCircle.module.css";

export const MainCircle = forwardRef<
  HTMLDivElement,
  { children?: ReactNode; diametr: number }
>(({ children, diametr }, ref) => {
  return (
    <div
      ref={ref}
      className={styles.mainCircle}
      style={{
        width: diametr,
        height: diametr,
      }}
    >
      <p className={styles.title}>HydroSoft.uk</p>
    </div>
  );
});
