import React from "react";
import styles from "./Background.module.css";

export function Background() {
  return (
    <>
      <p className={styles.title}>
        <span>Hydrosoft.uk</span>
      </p>
      <p className={styles.title}>
        <span>software </span>
      </p>
      <p className={styles.title}>
        <span>development</span>
      </p>
    </>
  );
}
