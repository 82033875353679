import React from "react";
import styles from "./App.module.css";
import { CircleList } from "./features/Apps/CirclesList/CirclesList";
import { Background } from "./features/Background/Background";
import { Description } from "./features/Descriptions/Descriptios";
import { useTheme } from "./hooks/useTheme/useTheme";

function App() {
  const [selectedApp, setSelectedApp] = React.useState<string>();
  useTheme();

  return (
    <section className={styles.main}>
      <Background />
      <div className={styles.content}>
        <CircleList setSelectedApp={setSelectedApp} />
        <Description selectedApp={selectedApp} />
      </div>
    </section>
  );
}

export default App;
